.card {
    position: relative;
    top: 0;
    z-index: 0;
    transition: top 0.4s;
}

/* .card:hover {
    top: -4px;
} */

.card:after {
    content: "";
    position: absolute;
    z-index: 1;
    width: 50px;
    height: 50px;
    background: #b3b7b7;
}

.card:after {
    bottom: -1px;
    right: -1px;
    clip-path: polygon(100% 0, 20% 100%, 100% 100%);
}

.top-left-angle-white, .bottom-right-angle-white {
    position: absolute;
    z-index: 2;
    width: 52px;
    height: 52px;
    background: white;
}

.top-left-angle-white {
    display: none;
    top: -5px;
    left: -1px;
    clip-path: polygon(0 0, 0% 100%, 80% 0);

}

.bottom-right-angle-white {
    bottom: -4px;
    right: -3px;
    clip-path: polygon(100% 0, 20% 100%, 100% 100%);
}

.top-left-angle-blue, .top-right-angle-blue {
    display: none;
    position: absolute;
    z-index: 2;
    width: 27px;
    height: 100%;
    background: #003B4F;
}

.top-left-angle-blue {
    top: 0;
    left: -26px;
    clip-path: polygon(0 0, 97% 100%, 100% 0);
}

.top-right-angle-blue {
    top: 0;
    right: -26px;
    clip-path: polygon(0 0, 0% 103%, 100% 0);
}

.top-left-angle-gray, .top-right-angle-gray {
    display: none;
    position: absolute;
    z-index: 2;
    width: 27px;
    height: 100%;
    background: #f2f2f2;
}

.top-left-angle-gray {
    top: 0;
    left: -26px;
    clip-path: polygon(0 0, 97% 100%, 100% 0);
}

.top-right-angle-gray {
    top: 0;
    right: -26px;
    clip-path: polygon(0 0, 0% 103%, 100% 0);
}

.top-left-angle-white-small {
    position: absolute;
    top: 0;
    left: -31px;
    width: 32px;
    height: 100%;
    z-index: 2;
    background: white;
    clip-path: polygon(100% -10%, 20% 100%, 100% 100%);
}

@media screen and (min-width: 1080px) {

    .card:before {
        content: "";
        position: absolute;
        z-index: 1;
        width: 50px;
        height: 50px;
        background: #b3b7b7;
    }
    .card:before {
        top: -1px;
        left: -1px;
        clip-path: polygon(0 0, 0% 100%, 80% 0);
    
    }
    
    .top-left-angle-white {
        display: block;
    }


    .top-left-angle-blue, .top-right-angle-blue, .top-left-angle-gray, .top-right-angle-gray {
        display: block;
    }

}