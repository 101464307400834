.menu_clip_background {
    position: absolute;
    z-index: 0;
	width: 56px;
    height: 70px;
    background-color: #003547;
    clip-path: polygon(50% 0, 100% 0%, 100% 75%, 50% 100%, 0 75%, 0 0%);
    transform: translate(0px, -16px);
}
@media screen and (min-width: 1080px) {
    .menu_clip_background {
        width: 520px;
        height: 440px;
        clip-path: polygon(25% 0%, 75% 0%, 100% 52.6%, 66% 100%, 25% 100%, 0% 50%);
        transform: translate(-361px, -144px);
    }
}